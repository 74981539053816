import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useMemo,
  useLayoutEffect
} from "react";
import { toast } from 'react-toastify';

/** Supporting Libs */
import {
  Button,
  Card,
  Container,
  Form,
  Modal,
  Tooltip,
  Badge,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Edit2, ExternalLink } from "react-feather";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as RushIcon } from '../../assets/icons/rush.svg'
import { ReactComponent as InspectionRequiredIcon } from '../../assets/icons/inspectionRequired.svg'

import { getAssignedUserModel } from "../../pages/frontDesk/helpers";

/** FullCalendar */
import FullCalendar from "@fullcalendar/react";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from '@fullcalendar/list';
import interactionPlugin from "@fullcalendar/interaction";
import dragula from "react-dragula";

import LoadingWrapper from "../../components/LoadingWrapper";

/** Services && Context */
import { AuthContext } from "../../contexts/JWTContext";
import {
  changeDueDate,
  changeInternalDueDate,
  getDueDateCalendarData,
  getDueDateCalendarResidentialData,
} from "../../services/ticketGroup";
import { updatePriority, updateTicketEventNote } from "../../services/ticket";
import useAppSelector from "../../hooks/useAppSelector";
import useScreenSize from "../../hooks/useScreenSize";
import useKeyBy from "../../hooks/useKeyBy";

import moment from "moment-timezone";

/** CSS */
import "./calendar.css";
import { loadJewishCalendarEvents } from "../../services/calendar";

const hideEmptyWeeks = () => {
  const table = document.getElementsByClassName('fc-scrollgrid-sync-table')[0];
  if (!table) {
    return;
  }

  const rows = table.getElementsByTagName('tr');
  // Loop through each row and check if all cells have the "fc-day-disabled" class
  for (let i = 0; i < rows.length; i++) {
    const cells = rows[i].getElementsByTagName('td');
    let allDisabled = true;
    for (let j = 0; j < cells.length; j++) {
      if (!cells[j].classList.contains('fc-day-disabled')) {
        allDisabled = false;
        break;
      }
    }
    // If all cells have the "fc-day-disabled" class, hide the row
    // if (allDisabled) {
    //   rows[i].style.display = 'none';
    // }
  }
}

const revertEmptyWeeks = () => {
  const table = document.getElementsByClassName('fc-scrollgrid-sync-table')[0];
  if (!table) {
    return;
  }

  const rows = table.getElementsByTagName('tr');
  // Loop through each row and check if all cells have the "fc-day-disabled" class
  for (let i = 0; i < rows.length; i++) {
    //const cells = rows[i].getElementsByTagName('td');
    let allDisabled = true;
    // for (let j = 0; j < cells.length; j++) {
    //   if (!cells[j].classList.contains('fc-day-disabled')) {
    //     allDisabled = false;
    //     break;
    //   }
    // }
    // If all cells have the "fc-day-disabled" class, hide the row
    //if (allDisabled) {
    // rows[i].style.display = 'table-row';
    //}
  }
}

const sortByPriority = (a, b) =>
  !a.priorityIndex && !b.priorityIndex
    ? 0
    : a.priorityIndex && b.priorityIndex
      ? a.priorityIndex - b.priorityIndex
      : a.priorityIndex && !b.priorityIndex
        ? -1
        : 1;

const EventContent = ({
  onPortfolioCollapse,
  onMouseEnter,
  onMouseLeave,
  ...eventInfo
}) => (
  <div
    className={`event px-1 regular-event ${eventInfo.event._def.extendedProps.priorityIndex === -1 ? 'yomtov' : ''}`}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    data-ticket-id={eventInfo.event._def.extendedProps.itemId}
    data-event-index={eventInfo.event._def.extendedProps.eventIndex}
    data-id={eventInfo.event._def.publicId}
    data-start={eventInfo.event._instance.range.start}
    data-priority-index={eventInfo.event._def.extendedProps.priorityIndex}
    title={eventInfo.event.title}
    data={eventInfo.event.id}
    style={{
      backgroundColor: eventInfo.event._def.extendedProps.firstColor,
    }}
  >
    {/* Red border */}
    {/* {eventInfo.event._def.extendedProps.pastDate &&
      !eventInfo.event._def.extendedProps.isActualDueDate && (
        <div className="red-border-left" />
      )
    } */}

    {/* {eventInfo.event._def.extendedProps.pastDays} */}

    <div className="d-flex flex-column">
      <div className="d-flex justify-content-between">
        <p
          className="eventTitle text-overflow"
          title={`${eventInfo.event.title} ${eventInfo.event.extendedProps.customerContactName &&
            `- ${eventInfo.event.extendedProps.customerContactName} - ${eventInfo.event.extendedProps.propertyTypeName}`}`}
          style={{
            marginRight: '0.4px',
            color: eventInfo.event._def.extendedProps.isYomtov
              ? "white"
              : "rgb(24, 25, 26)",
            fontSize: eventInfo.event._def.extendedProps.isYomtov
              ? "10px"
              : "12px",
          }}
        >
          <b className={eventInfo.event._def.extendedProps.isYomtov ? 'white' : 'black'}>{eventInfo.event.title} {eventInfo.event._def.extendedProps.isYomtov ? '' : ':'}</b> {eventInfo.event.extendedProps.customerContactName &&
            ` ${eventInfo.event.extendedProps.customerContactName} - `}
          {eventInfo.event.extendedProps.propertyTypeName}
        </p>
        <div className="d-flex justify-content-between icons-container">
          {eventInfo.event._def.extendedProps.isInspectionRequired && (
            <span className="inspection-required-container" title={"Inspection required"}>
              <InspectionRequiredIcon />
              {/* <img src={inspectionIcon} width={13} height={13} /> */}
            </span>
          )}
          {eventInfo.event._def.extendedProps.isRush && (
            <span className="rush-container" title={"Rush"}>
              <RushIcon />
            </span>
          )}
        </div>
      </div>
      {eventInfo.event._def.extendedProps.pastDate &&
        !eventInfo.event._def.extendedProps.isActualDueDate && (
          <div style={{ marginTop: -6 }}>
            <span className="past-days">{-1 * eventInfo.event._def.extendedProps.pastDays} days ago</span>
          </div>
        )
      }
    </div>
  </div >
);

const PortfolioTickets = ({ onPortfolioExpand, ...eventInfo }) => (
  <div
    className="portfolio-item-wrapper px-1"
    title={eventInfo.event.title}
    data-portfolio-id={eventInfo.event._def.extendedProps.portfolioId}
    data-event-index={eventInfo.event._def.extendedProps.eventIndex}
    data-actual-due-date={eventInfo.event._def.extendedProps.actualDueDate}
    data-priority-index={eventInfo.event._def.extendedProps.priorityIndex}
    data-start={eventInfo.event._instance.range.start}
    data={eventInfo.event.id}
    style={{
      backgroundColor: "black",
      borderRadius: 3,
      position: "relative",
    }}
  >
    <div className="portfolio-item">
      <p
        className="portfolio-title"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onPortfolioExpand(
            eventInfo.event._def.extendedProps.portfolioId,
            eventInfo.event._def.extendedProps.actualDueDate
          );
        }}
      >
        {eventInfo.event._def.extendedProps.portfolioName}
      </p>
      {eventInfo.event._def.extendedProps.portfolioTickets.map((item) => (
        <p
          key={item.id}
          className="text-overflow portfolio-ticket-title">
          {item.title}
        </p>
      ))}
    </div>
    <div className="portfolio-property text-overflow">
      {eventInfo.event.extendedProps.customerContactName &&
        `${eventInfo.event.extendedProps.customerContactName} - `}
      {eventInfo.event.extendedProps.propertyTypeName}
    </div>
  </div>
);

const Calendar = ({ residential = false, internal = false }) => {
  const [ticketEvents, setTicketEvents] = useState([]);
  const [loadedMonth, setLoadedMonth] = useState(0);
  const [loadedYear, setLoadedYear] = useState(0);
  const [actualDueDate, setActualDueDate] = useState(!internal);
  const actualDueDateRef = useRef(!internal);
  actualDueDateRef.current = actualDueDate;

  const [internalDueDate, setInternalDueDate] = useState(internal);
  const internalDueDateRef = useRef();
  internalDueDateRef.current = internalDueDate;

  const [includeCompleted, setIncludeCompleted] = useState(false);
  const includeCompletedRef = useRef(includeCompleted);

  const [loading, setLoading] = useState(false);
  const [tooltipTicket, setTooltipTicket] = useState({});
  const [ticketNoteToEditId, setTicketNoteToEditId] = useState(0);
  const [note, setNote] = useState("");
  const [dispose, setDisposed] = useState(false);

  const propertyTypes = useAppSelector((state) => state.appData.propertyTypes);
  const ticketStatusTypesMap = useAppSelector(
    (state) => state.appData.ticketStatusTypesMap
  );
  const usersMap = useAppSelector((state) => state.appData.usersMap);
  const users = useAppSelector((state) => state.appData.users);
  const [userId, setUserId] = useState();
  const userIdRef = useRef();
  userIdRef.current = userId;

  const portfolios = useAppSelector((state) => state.appData.portfolios);
  const context = useContext(AuthContext);
  const userRolesRef = useRef();
  userRolesRef.current = context.user?.roles || [];
  const ticketEventsRef = useRef([]);
  const tooltipRef = useRef();
  const [tooltipHeight, setTooltipHeight] = useState(0);

  ticketEventsRef.current = ticketEvents;
  const navigate = useNavigate();

  const calendarApi = useRef(null);
  const isDataLoaded = useRef(false);
  const residentialRef = useRef(residential);
  const { height, isMobile } = useScreenSize();

  const propertyTypesMap = useKeyBy(propertyTypes, "id");
  // const [drakeInstance, setDrakeInstance] = useState();
  const drakeInstanceRef = useRef();

  const [jewishHolidays, setJewishHolidays] = useState([]);
  //const [radioValue, setRadioValue] = useState('1');

  const radios = [
    { name: 'Regular', value: false },
    { name: 'Internal', value: true },
  ];

  // const now = new Date()
  // const threeWeeksLater = new Date(now.getTime() + 21 * 24 * 60 * 60 * 1000) // calculate 3 weeks later
  // const duration = { start: now, end: threeWeeksLater }
  const viewType = localStorage.getItem('DD_view_type') ?? "dayGridMonth";
  localStorage.setItem('DD_type', internal ? 'internal' : 'regular');

  useEffect(() => {
    const loadJewishEvents = async (year = "now") => {
      const jewishHolidaysData = await loadJewishCalendarEvents(year);
      let filtered = jewishHolidaysData.items.filter(x => !x.title.toLowerCase().includes('shabbat'));
      setJewishHolidays(filtered);
    }

    if (jewishHolidays.length == 0) {
      loadJewishEvents();
      return;
    }

    const lastLoadedDate = new Date(jewishHolidays[jewishHolidays.length - 1].date);
    if (lastLoadedDate.getFullYear() != loadedYear) {
      console.log(`Loading Hebcal data since year was changed... from -> ${lastLoadedDate.getFullYear()}, to -> ${loadedYear}`);
      loadJewishEvents(loadedYear);
    }

  }, [loadedMonth, loadedYear]);

  useEffect(() => {
    const move = (e) => {
      if (e.keyCode == 37) { // left
        calendarApi.current.getApi().prev();
        setupDragula();
      }
      else if (e.keyCode == 39) { // right
        calendarApi.current.getApi().next();
        setupDragula();
      }
    }

    document.addEventListener("keydown", move, false);
    return () => {
      document.removeEventListener("keydown", move, false);
    }
  }, [calendarApi.current]);

  useEffect(() => {
    if (
      context.isAuthenticated &&
      (!isDataLoaded.current || residentialRef.current !== residential) &&
      calendarApi.current &&
      !!propertyTypes.length &&
      !!Object.keys(usersMap).length
    ) {
      isDataLoaded.current = true;
      residentialRef.current = residential;
      if (!internal) {
        setInternalDueDate(false);
        internalDueDateRef.current = false;
      }
      else {
        setInternalDueDate(true);
        internalDueDateRef.current = true;
      }
      const month =
        calendarApi.current._calendarApi.view.currentStart.getMonth() + 1;
      const year =
        calendarApi.current._calendarApi.view.currentStart.getFullYear();
      loadCurrentMonthData(month, year);

      return () => {
        setTicketEvents([]);
        //setUserId(null);
        actualDueDateRef.current = null;
        ticketEventsRef.current = null;
        isDataLoaded.current = null;
        internalDueDateRef.current = null;
        residentialRef.current = null;

        setDisposed(true);
        setTimeout(() => {
          setDisposed(false);
        }, 200)
      }
    }
  }, [context.isAuthenticated, calendarApi.current, residential, propertyTypes, usersMap, userId, internalDueDate]);

  useEffect(() => {
    if (!internal) {
      setActualDueDate(true);
      actualDueDateRef.current = true;
    }
    else {
      setActualDueDate(false);
      actualDueDateRef.current = false;
    }
  }, [internal])

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        setupDragula();
      }, 200);
    }
  }, [loading])

  const setupDragula = () => {
    const elems = document.getElementsByClassName(
      "fc-daygrid-day-events"
    );

    if (elems.length == 0) {
      return;
    }

    if (!drakeInstanceRef.current) {
      console.log("Setting up dragula instance, this should be visible only once");
      drakeInstanceRef.current = dragula([...elems], {
        revertOnSpill: true,
        copy: false,
        moves: function (el) {
          return !el.children[0].children[0].children[0].classList.contains('yomtov');
        }
      });

      drakeInstanceRef.current.on("drop", drakeDropHandler);
    }

    while (drakeInstanceRef.current.containers.length > 0) {
      drakeInstanceRef.current.containers.pop();
    }

    for (let elem of elems) {
      drakeInstanceRef.current.containers.push(elem);
    }
  }

  const drakeDropHandler = (el, target, source) => {

    const getDate = (el) =>
      el.parentElement.parentElement.getAttribute("data-date");

    const getEventAttr = (
      el,
      key = "data-id",
      className = "event"
    ) =>
      el
        .getElementsByClassName(className)
        .item(0)
        ?.getAttribute(key);

    const targetDay = getDate(target);
    const sourceDay = getDate(source);
    const ticketGroupId = getEventAttr(el);
    const ticketId = getEventAttr(el, "data-ticket-id");
    const portfolioId = getEventAttr(
      el,
      "data-portfolio-id",
      "portfolio-item-wrapper"
    );
    let eventIndex = getEventAttr(
      el,
      "data-event-index",
      "portfolio-item-wrapper"
    );
    if (!eventIndex) {
      eventIndex = getEventAttr(
        el,
        "data-event-index",
        "event"
      );
    }


    const revert = () => drakeInstanceRef.current.cancel(true);
    if (targetDay !== sourceDay) {
      if (ticketId === null) {
        revert();
        return;
      }

      //handleEventDayChange(ticketGroupId, targetDay, revert);
      // NOTE: uncomment line below and delete line above for "DD calendar new feature"
      handleEventDayChange(
        Number(eventIndex),
        targetDay,
        revert
      );
    } else {
      if (!ticketId && !portfolioId) {
        revert();
        return;
      }
      const start = getEventAttr(el, "data-start");
      const portfolioStart = getEventAttr(
        el,
        "data-start",
        "portfolio-item-wrapper"
      );
      let newPriority;
      for (let i = 0; i < target.children.length; i++) {
        const child = target.children.item(i);
        if (getEventAttr(child) === ticketGroupId) {
          newPriority = i + 1;
        }
      }
      if (!newPriority) {
        newPriority = target.children.length;
      }
      handleEventOrderChange(
        ticketId || portfolioId,
        newPriority,
        revert,
        moment(start || portfolioStart).format("YYYY-MM-DD")
      );
    }
  };

  const closeTooltip = () => setTooltipTicket({});

  const navigateToInternalDDCalendarHandler = () => {
    if (!internal) {
      let url = '/calendar/internal-dueDate-commercial';
      if (residential) {
        url = '/calendar/internal-dueDate-residential';
      }

      setInternalDueDate(true);
      internalDueDateRef.current = true;
      navigate(url);
    }
    else {
      let url = '/calendar/dueDate-commercial';
      if (residential) {
        url = '/calendar/dueDate-residential';
      }

      setInternalDueDate(false);
      internalDueDateRef.current = false;
      navigate(url);
    }
  }

  useLayoutEffect(() => {
    setTooltipHeight(tooltipRef.current?.getBoundingClientRect().height);
  }, [tooltipTicket.id]);

  const goBack = () => {
    navigate("/");
  };

  const handleEventClick = (info) =>
    info.event.extendedProps.ticketGroupId &&
    info.jsEvent.target.tagName !== "U" &&
    navigate(
      `/dashboard/job/${info.event.extendedProps.ticketGroupId}/${info.event.extendedProps.itemId}`,
      { state: { from: window.location.pathname } }
    );

  const loadCurrentMonthData = async (
    month,
    year,
    dateToGo,
    _includeCompleted = includeCompletedRef.current,
    _userId = userId,
  ) => {
    setLoading(true);
    setDisposed(false);
    setTicketEvents([]);
    const getData = residential
      ? getDueDateCalendarResidentialData
      : getDueDateCalendarData;
    const data = await getData(year, month, _includeCompleted);

    const newTicketEvents = [];
    if (data) {
      // commented out since it's blinking
      // setTimeout(() => {
      //   const scrolledCalendar = document.querySelector(
      //     ".fc-scroller-liquid-absolute"
      //   );
      //   scrolledCalendar &&
      //     scrolledCalendar.scrollTo(0, scrolledCalendar.scrollHeight);
      // });

      const currentDate = moment().clone().tz("America/New_York");
      const currentDayStartDate = moment()
        .startOf("day")
        .clone()
        .tz("America/New_York");

        data.forEach((ticketGroup) => {
        const customerContactName = ticketGroup.customerContact?.customer?.name;
        ticketGroup.tickets.forEach((ticket) => {
          if (
            !ticketGroup.dueDate &&
            !ticket.dueDateOverride &&
            !ticket.portfolioId
          ) {
            return;
          }
          
          const item = {
            id: ticket.id,
            priorityIndex: ticket.priorityIndex,
            ticketGroupId: ticketGroup.id,
            title: ticket?.property?.address?.split(",")[0] || ticketGroup.name,
            isRush: ticket.isRush,
            isInspectionRequired: false,
            isYomtov: false,
            customerContactName,
          };

          item.isInspectionRequired =
            ticket.isInspectionRequired === true &&
            (ticket.inspections.length === 0 || ticket.inspections.some(x => !x.inspectorId)) &&
            ticket.ticketStatuses.some(
              (x) => !x.completedDate && x.ticketStatusTypeId == 2
            );

          const groupDueDate = moment(ticketGroup.dueDate);
          // .utc()
          // .clone()
          // .tz('America/New_York');

          let date = ticket.dueDateOverride;
          //console.clear();
          //console.log(ticket?.property?.address?.split(",")[0] || ticketGroup.name, date);
          if (internalDueDateRef.current) {
            date = ticket.internalDueDate ?? groupDueDate;
          }
          
          const dueDate = date
            ? moment(date)
            : groupDueDate;
          // .utc()
          // .clone()
          // .tz('America/New_York') : groupDueDate;

          let ticketStep = ticket.ticketSteps.find(
            (ticketStep) => !ticketStep.completedDate
          );

          if (!ticketStep && ticket.ticketSteps && ticket.ticketSteps.length > 0) {
            ticketStep = ticket.ticketSteps[ticket.ticketSteps.length - 1];
          }

          // const ticketStatus = ticket.ticketStatuses.find((ticketStatus) => !ticketStatus.completedDate);

          //console.log(userId, _userId, userIdRef, residential);
          //if (userIdRef.current && userIdRef.current != '' && ticketStep?.assignedToId != userIdRef.current) {
          //  return;
          //}

          var assignedUser = getAssignedUserModel(ticket, usersMap);

          if (userIdRef.current && userIdRef.current !== '') {
            if (assignedUser == null) {
              return;
            }
            if (assignedUser.id !== userIdRef.current) {
              return;
            }
          }

          const ticketStatus =
            ticket.ticketStatuses[ticket.ticketStatuses.length - 1];
          const propertyTypeName =
            propertyTypesMap[ticket.property?.propertyTypeId]?.name;
          if (!ticketStep) {
            item.firstColor = usersMap[ticketStatus?.assignedToId]?.color;
          }
          else {
            item.firstColor = usersMap[ticketStep?.assignedToId]?.color;
          }
          if (assignedUser) {
            item.firstColor = assignedUser.color;
          }

          item.secondColor =
            ticketStatusTypesMap[ticketStatus?.ticketStatusTypeId]?.color;
          item.note = ticket.calendarNote;
          item.propertyTypeName = propertyTypeName;

          item.groupDueDate = groupDueDate.format("YYYY-MM-DD");
          item.actualDueDate = dueDate.format("YYYY-MM-DD");
          if (dueDate < currentDate) {
            item.start = currentDate.format("YYYY-MM-DD");
            item.formattedDueDate = currentDate.format("YYYY-MM-DD");
            item.pastDate = dueDate < currentDayStartDate;

            item.pastDays = Math.round(moment.duration(groupDueDate.diff(currentDayStartDate)).asDays());
          } else {
            item.start = dueDate.format("YYYY-MM-DD");
            item.formattedDueDate = dueDate.format("YYYY-MM-DD");
          }

          // if (item.title.indexOf("541") != -1) {
          //   console.log(item);
          // }

          if (ticket.portfolioId) {
            const foundPortfolioItem = newTicketEvents.find(
              (x) =>
                x.portfolioId == ticket.portfolioId &&
                (
                    (internalDueDate && x.formattedDueDate === item.formattedDueDate)
                    || (!internalDueDate && x.formattedDueDate === item.formattedDueDate)
                )
            );
            const portfolioName = portfolios.find(
              (x) => x.id === ticket.portfolioId
              )?.name;

            item.portfolioName = portfolioName;
            item.portfolioId = ticket.portfolioId;

            const portfolioItem = foundPortfolioItem || {
              id: ticket.portfolioId,
              portfolioId: ticket.portfolioId,
              portfolioTickets: [],
              portfolioName,
              groupDueDate: item.groupDueDate,
              actualDueDate: item.actualDueDate,
              start: item.start,
              formattedDueDate: item.formattedDueDate,
              pastDate: item.pastDate,
            };
            portfolioItem.portfolioTickets.push(item);
            portfolioItem.portfolioTickets.sort(sortByPriority);
              portfolioItem.priorityIndex = portfolioItem.portfolioTickets[0].priorityIndex;
            !foundPortfolioItem && newTicketEvents.push(portfolioItem);
          } else if (ticketGroup.dueDate || ticket.dueDateOverride) {
            newTicketEvents.push(item);
          }
        });
      });

    }


    for (let ev of jewishHolidays) {
      const date = moment(ev.date).format("YYYY-MM-DD");
      newTicketEvents.push({
        title: ev.title,
        start: new Date(ev.date).toISOString(),
        formattedDueDate: date,
        actualDueDate: date,
        firstColor: "#850000",
        isYomtov: true,
        priorityIndex: -1
      });
    }

    newTicketEvents.sort(sortByPriority);

    try {


      for (var i = 0; i < newTicketEvents.length; i++) {
        newTicketEvents[i].eventIndex = i;
      }


      setTicketEvents(
        newTicketEvents.map((item) =>
          item.portfolioTickets?.length === 1
            ? {
              ...item.portfolioTickets[0],
              portfolioId: null,
              portfolioName: null,
              eventIndex: item.eventIndex
            }
            : item
        )
      );

      const api = calendarApi.current.getApi();
      api.render();

      if (dateToGo) {
        if (api) {
          api.gotoDate(dateToGo);
        }
      }
    }
    catch (e) {
      toast.error(e)
    }
    setLoading(false);
    setTimeout(() => {
      revertEmptyWeeks();
      hideEmptyWeeks();
    }, 0);
  };

  const handleEventDrop = (info) => {
    const updatedEvents = ticketEventsMemo.map((event) => {
      if (event.id === info.event.id) {
        return {
          ...event,
          start: info.event.start,
          end: info.event.end,
        };
      }
      // Check if the event is dropped on the same day as another event
      if (info.event.start.toISOString().slice(0, 10) ===
        event.start.toISOString().slice(0, 10)) {
        return {
          ...event,
          extendedProps: {
            ...event.extendedProps,
            order: event.extendedProps.order + 1, // Increment the order of the other event
          },
        };
      }
      return event;
    });

    setTicketEvents(updatedEvents);
  };

  const handleEventOrderChange = (ticketId, priority, revert, start) => {
    //console.log(ticketId, priority, start);

    const parts = start.split('-');
    //console.log(parts);

    const year = Number(parts[0]);
    const month = Number(parts[1]);
    const day = Number(parts[2]);

    const test = new Date(year, month - 1, day, 20, 0, 0)
    console.log(test, "Actual date");

    let _start = new Date(start);
    //console.log(_start);

    // _start.setDate(_start.getDate());
    _start = test.toISOString().split("T")[0];
    //console.log(ticketId, priority, _start, actualDueDateRef.current);
    //console.log(_start);
    // if (!userRolesRef.current.some((role) =>
    //     [USER_ROLES.ADMIN, USER_ROLES.PRIORITIZER].includes(role)
    // )) {
    //     revert();
    //     return;
    // }

    const ticket = ticketEventsRef.current.find(
      (item) => item.id === Number(ticketId)
    );
    if (ticket.priorityIndex === priority) {
      return;
    }

    const ticketsOfTheDay = ticketEventsRef.current.filter((item) => {
      if (!item.id) {
        return false;
      }
      if (actualDueDateRef.current) {
        return item.actualDueDate === _start;
      }

      // console.log(item.formattedDueDate, start);
      return (
        new Date(item.formattedDueDate).getTime() == new Date(_start).getTime()
      );
    });
    console.log(ticketsOfTheDay);
    const ticketOldIndex = ticketsOfTheDay.findIndex(
      (item) => item.id === Number(ticketId)
    );
    const [item] = ticketsOfTheDay.splice(ticketOldIndex, 1);
    ticketsOfTheDay.splice(priority - 1, 0, item);
    const ticketsToUpdate = ticketsOfTheDay
      .filter(Boolean)
      .map((totd) => totd.portfolioTickets || totd)
      .flat()
      .map((item, index) => ({
        ...item,
        priorityIndex: index + 1,
      }));
    ticketsToUpdate.sort(sortByPriority);
    ticketEventsRef.current = ticketEventsRef.current.map((item) => {
      const updateItem = ticketsToUpdate.find((i) => i.id === item.id);
      return { ...(updateItem || item) };
    });
    ticketEventsRef.current.sort(sortByPriority);

    Promise.all(
      ticketsToUpdate
        .filter((x) => !x.isYomtov)
        .map((item) => {
          console.log(item.title, item.priorityIndex);
          updatePriority(Number(item.id), item.priorityIndex);
        })
    ).then((x) => {
      // small fix
      try {
        const month =
          calendarApi.current._calendarApi.view.currentStart.getMonth() + 1;
        const year =
          calendarApi.current._calendarApi.view.currentStart.getFullYear();
        setDisposed(true);
        setTicketEvents([]);

        console.log("Loading the data after drop");
        setDisposed(false);
        setTicketEvents([]);
        ticketEventsRef.current = [];
        loadCurrentMonthData(month, year,);
      }
      catch (e) {
        toast.error(e);
      }
    });
    // someApiRequest(
    //     ticketsToUpdate.map((item) => ({
    //         id: Number(item.id),
    //         priorityIndex: item.priorityIndex,
    //     }))
    // );
  };

  const handleEventDayChange = async (eventIndex, rangeStart, revert) => {
    
    const eventItem = ticketEventsRef.current[eventIndex];
    //const dropDateMoment = moment(rangeStart);//.endOf("day");
    //console.log(dropDateMoment);
    const dropDate = new Date(rangeStart).toISOString(); //dropDateMoment.toISOString();

    console.log("dropped at -", eventItem, dropDate.split("T")[0]);
    // Asked by Debbie to remove
    // if (internalDueDateRef.current && moment(dropDate).isAfter(eventItem.groupDueDate, 'day')) {
    //   const answer = window.confirm(`The actual due date for this job is (${moment(eventItem.groupDueDate).format("MM/DD/YYYY")}). You are trying to change it after the actual due date. Are you sure?`);
    //   if (!answer) {
    //     revert();
    //     return;
    //   }
    // }


    //console.log(ticketEventsRef.current);
    // let currentItem = ticketEventsRef.current.find(
    //   (x) => Number(x.ticketGroupId) == Number(publicId)
    // );

    //console.log(currentItem);

    // setTicketEvents(newArray);
    //console.log();
    // setTicketEvents(old => {

    //     let newData = [...without];
    //     return newData;
    // })
    // setTicketEvents(old => {
    //     return old.filter(x => Number(x.ticketGroupId) != Number(publicId))
    // });
    // if (dropDateMoment.diff(moment()) < 0) {
    //     revert();
    //     return;
    // }
    // const dragDate = moment(dropInfo.view.dateEnv).toISOString();

    await Promise.all(
      (eventItem?.portfolioTickets || [eventItem]).map((ticket) => {
        if (internalDueDateRef.current) {
          changeInternalDueDate(ticket.id, dropDate);
        }
        else {
          changeDueDate(ticket.id, dropDate)
        }
      })
    );
    setLoading(true);
    setDisposed(true);
    setTicketEvents([]);
    setDisposed(false);
    setTimeout(() => {
      const month =
        calendarApi.current._calendarApi.view.currentStart.getMonth() + 1;
      const year =
        calendarApi.current._calendarApi.view.currentStart.getFullYear();
      loadCurrentMonthData(month, year, dropDate.split("T")[0]);
    }, 1000);
  };

  const ticketEventsMemo = useMemo(
    () =>

      

      ticketEvents.map((item) => {
        // let _start = new Date(
        //   actualDueDate ? item.actualDueDate : item.formattedDueDate
        // );
        // let str = _start.toISOString().split("T")[0];
        return {
          ...item,
          id: item.ticketGroupId,
          itemId: item.id,
          //start: str,
          // NOTE: uncomment line below and delete line above for "DD calendar new feature"
          start: actualDueDate ? (item.actualDueDate ?? item.groupDueDate) : item.formattedDueDate,
          isActualDueDate: actualDueDate,
        };
      }),
    [ticketEvents, actualDueDate]
  );

  if (!propertyTypes.length) {
    return null;
  }

  const handleIncludeCompletedChange = (e) => {
    setIncludeCompleted(e.target.checked);
    includeCompletedRef.current = e.target.checked;

    if (e.target.checked && !actualDueDate) {
      setActualDueDate(true);
    }
    const month =
      calendarApi.current._calendarApi.view.currentStart.getMonth() + 1;
    const year =
      calendarApi.current._calendarApi.view.currentStart.getFullYear();
    loadCurrentMonthData(month, year, includeCompletedRef.current);
  };

  const handleEventNoteEdit = (id, note) => {
    const ticket = ticketEvents.find((item) => item.id === id);
    setTicketNoteToEditId(id);
    setNote(ticket.note || "");
    closeTooltip();
  };

  const handleTicketNoteSave = () => {
    updateTicketEventNote(ticketNoteToEditId, note).then(() => {
      const month =
        calendarApi.current._calendarApi.view.currentStart.getMonth() + 1;
      const year =
        calendarApi.current._calendarApi.view.currentStart.getFullYear();
      loadCurrentMonthData(month, year);
    });
    setTicketNoteToEditId(0);
    setNote("");
  };

  const onMouseLeaveEvent = () => {
    if (tooltipRef.current.matches(":hover")) {
      return;
    }

    closeTooltip();
  };

  const handlePortfolioExpand = (portfolioId, actualDueDate) => {
    const scrolledCalendar = document.querySelector(
      ".fc-scroller-liquid-absolute"
    );
    const scrollTop = scrolledCalendar.scrollTop;
    const newTicketEvents = [...ticketEvents];
    const portfolioItemIndex = newTicketEvents.findIndex(
      (item) =>
        item.portfolioId === portfolioId && item.actualDueDate === actualDueDate
    );

    const [portfolioItem] = newTicketEvents.splice(portfolioItemIndex, 1);

    newTicketEvents.splice(portfolioItemIndex, 0, ...portfolioItem.portfolioTickets);


    for (var i = 0; i < newTicketEvents.length; i++) {
        newTicketEvents[i].eventIndex = i;
    }

    setTicketEvents(
        newTicketEvents.map((item) =>
            item.portfolioTickets?.length === 1
                ? {
                    ...item.portfolioTickets[0],
                    portfolioId: null,
                    portfolioName: null,
                    eventIndex: item.eventIndex
                }
                : item
        )
    );

    setTimeout(() => scrolledCalendar?.scrollTo({ top: scrollTop - 10, left: 0, behavior: 'smooth' }));
  };

  const handlePortfolioCollapse = (portfolioId, actualDueDate) => {
    const scrolledCalendar = document.querySelector(
      ".fc-scroller-liquid-absolute"
    );
    const scrollTop = scrolledCalendar.scrollTop;
    const newTicketEvents = [...ticketEvents];
    const portfolioTickets = newTicketEvents.filter(
      (item) =>
        item.portfolioId === portfolioId && item.actualDueDate === actualDueDate
    );

    const portfolioFirstTicketindex = newTicketEvents.findIndex(
      (item) =>
        item.portfolioId === portfolioId && item.actualDueDate === actualDueDate
    );

    const firstTicket = newTicketEvents[portfolioFirstTicketindex];

    const portfolioItem = {
      portfolioId,
      portfolioTickets,
      portfolioName: portfolios.find((x) => x.id === portfolioId).name,
      groupDueDate: firstTicket.groupDueDate,
      actualDueDate: firstTicket.actualDueDate,
      start: firstTicket.start,
      formattedDueDate: firstTicket.formattedDueDate,
      pastDate: firstTicket.pastDate,
    };

    const filteredTicketEvents = newTicketEvents.filter(
      (item) =>
        item.portfolioId !== portfolioId || item.actualDueDate !== actualDueDate
    );

    filteredTicketEvents.splice(portfolioFirstTicketindex, 0, portfolioItem);

    setTicketEvents(filteredTicketEvents);

    setTimeout(() =>
      scrolledCalendar?.scrollTo({
        top: scrollTop - 10,
        left: 0,
        behavior: "smooth",
      })
    );
  };

  const actualDueDateSwitchHandler = e => {
    const { checked } = e.target;
    setActualDueDate(checked);

    if (checked) {
      revertEmptyWeeks();
    }
    else {
      setTimeout(() => {
        hideEmptyWeeks();
      }, 0)
    }
  }

  // const handleViewTitle = (arg) => {
  //   const { view } = arg;
  //   const start = moment(view.activeStart).format('MMM D');
  //   const end = moment(view.activeEnd).subtract(1, 'days').format('MMM D');
  //   console.log(start, end);
  //   return `${start} - ${end}`;
  // };

  return (
    <React.Fragment>
      <Helmet title="Calendar" />
      <Tooltip
        ref={tooltipRef}
        onMouseLeave={closeTooltip}
        show={!!tooltipTicket.id}
        placement="top"
        arrowProps={{
          style: { marginLeft: (tooltipTicket.width || 100) / 2 - 8 },
        }}
        style={{
          position: "absolute",
          top: tooltipTicket.top - tooltipHeight || 53,
          left: tooltipTicket.left || 850,
          opacity: Number(!!tooltipTicket.id),
        }}
      >
        <div>{tooltipTicket.note}</div>
        <Link
          to={`/dashboard/job/${tooltipTicket.ticketGroupId}/${tooltipTicket.id}`}
          state={{ from: window.location.pathname }}
          className="btn me-2 text-light"
          target="_blank"
        >
          <ExternalLink />
        </Link>
        <Button
          variant="ghost"
          className="ms-2 text-info"
          onClick={() => handleEventNoteEdit(tooltipTicket.id)}
        >
          <Edit2 />
        </Button>
      </Tooltip>
      <Modal
        show={!!ticketNoteToEditId}
        onHide={() => setTicketNoteToEditId(0)}
      >
        <Modal.Header closeButton>Edit ticket note</Modal.Header>
        <Modal.Body className="text-center m-3">
          <Form.Control
            name="ticketNote"
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="mr-2"
            onClick={() => setTicketNoteToEditId(0)}
          >
            Close
          </Button>
          <Button onClick={handleTicketNoteSave}>Save changes</Button>
        </Modal.Footer>
      </Modal>
      <Container fluid className="p-0">
        <div className="d-flex justify-content-between calendar-header">
          <div className="d-flex align-items-center">
            <h3 className="calendar-title">
              {residential
                ? (internal ? "Internal Res. Due Date calendar" : "Res. Due Date calendar")
                : (internal ? "Internal Comm. Due Date calendar" : "Comm. Due Date calendar")}
            </h3>
            <FontAwesomeIcon
              style={{
                marginLeft: 10,
                height: 25,
                width: 25,
                cursor: "pointer",
              }}
              icon={faTimes}
              onClick={goBack}
            />
          </div>
          {(<div className={`d-flex align-items-center actions-container ${loading ? 'is-disabled' : null}`} aria-disabled={loading}>
            <Form.Check
              id="calendar-due-date-switch"
              className="calendar-due-date-switch h4 me-4"
              type="switch"
              label="Include completed"
              checked={includeCompleted}
              onChange={handleIncludeCompletedChange}
              disabled={loading}
            />
            <Form.Select
              name="userId"
              disabled={loading}
              className="users-dropdown"
              value={userId}
              size="md"
              onChange={(e) => {
                setUserId(e.target.value);
                userIdRef.current = e.target.value;
              }}
            >
              <option value="">All Users</option>
              {users.filter(u => u.active).map((user, index) => (
                <option key={index} value={user.id}>{`${user.firstName} ${user.lastName}`}</option>
              ))}
            </Form.Select>

            <ButtonGroup className="ms-2 me-1 mb-1" disabled={loading}>
              {radios.map((radio, idx) => (
                <ToggleButton
                  disabled={loading}
                  style={{ height: '33px' }}
                  key={idx}
                  id={`radio-${idx}`}
                  type="radio"
                  variant={internalDueDate === radio.value ? "primary" : "secondary"}
                  name="radio"
                  value={radio.value}
                  checked={internalDueDate === radio.value}
                  onChange={navigateToInternalDDCalendarHandler}
                >
                  {radio.name}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </div>)}
        </div>
        <Card className={internal ? 'internal  mt-2' : 'regular  mt-2'}>
          <Card.Body className="calendar-container-card">
            <LoadingWrapper loading={loading}>
              {!dispose && (
                <FullCalendar
                  eventOrder={(a, b) =>
                    a.priorityIndex && b.priorityIndex
                      ? a.priorityIndex - b.priorityIndex
                      : 0
                  }
                  // editable={true}
                  plugins={[
                    bootstrapPlugin,
                    dayGridPlugin,
                    timeGridPlugin,
                    interactionPlugin,
                    listPlugin,
                  ]}
                  dayMaxEvents={isMobile}
                  // showNonCurrentDates={false}
                  // contentHeight={500}
                  //dayMaxEvents={false}
                  //eventLimit={false}
                  moreLinkClick="listWeek"
                  // fixedWeekCount={false}
                  // eventDurationEditable
                  themeSystem="bootstrap"
                  customButtons={{
                    customNextButton: {
                      text: isMobile ? ">" : "Next",
                      click: () => {
                        if (calendarApi.current && context.isAuthenticated) {
                          calendarApi.current._calendarApi.next();

                          let _month =
                            calendarApi.current._calendarApi.view.currentStart.getMonth() +
                            1;
                          let _year =
                            calendarApi.current._calendarApi.view.currentStart.getFullYear();
                          if (loadedYear !== _year || loadedMonth !== _month) {
                            setLoadedMonth(_month);
                            setLoadedYear(_year);
                            loadCurrentMonthData(_month, _year);
                          }
                        }
                      },
                    },
                    customPreviousButton: {
                      text: isMobile ? "<" : "Previous",
                      click: () => {
                        if (calendarApi.current && context.isAuthenticated) {
                          calendarApi.current._calendarApi.prev();
                          let _month =
                            calendarApi.current._calendarApi.view.currentStart.getMonth() +
                            1;
                          let _year =
                            calendarApi.current._calendarApi.view.currentStart.getFullYear();
                          if (loadedYear !== _year || loadedMonth !== _month) {
                            setLoadedMonth(_month);
                            setLoadedYear(_year);
                            loadCurrentMonthData(_month, _year);
                          }
                        }
                      },
                    },
                    dayGridMonth: {
                      text: "month",
                      click: () => {
                        calendarApi.current._calendarApi.changeView(
                          "dayGridMonth"
                        );
                        localStorage.setItem('DD_view_type', "dayGridMonth");
                      },
                      bootstrapFontAwesome: isMobile ? "calendar-days" : "",
                    },
                    timeGridWeek: {
                      text: "week",
                      click: () => {
                        calendarApi.current._calendarApi.changeView("timeGridWeek");
                        localStorage.setItem('DD_view_type', "timeGridWeek");
                      },
                      bootstrapFontAwesome: isMobile ? "list" : "",
                    },
                    timeGridDay: {
                      text: "day",
                      click: () => {
                        calendarApi.current._calendarApi.changeView("timeGridDay");
                        localStorage.setItem('DD_view_type', "timeGridDay");
                      },
                      bootstrapFontAwesome: isMobile ? "list" : "",
                    },
                  }}
                  // duration={duration}
                  ref={calendarApi}
                  navLinkDayClick={() => alert("Day clicked")}
                  navLinkWeekClick={() => alert("Week clicked")}
                  initialView={isMobile ? "listWeek" : viewType}
                  height={height - 200}
                  initialDate={new Date().toISOString().split("T")[0]}
                  headerToolbar={{
                    left: "customPreviousButton customNextButton",
                    center: "title",
                    right: isMobile
                      ? "listWeek,dayGridMonth"
                      : "dayGridMonth,timeGridWeek,timeGridDay",
                  }}
                  events={ticketEventsMemo}
                  eventOverlap
                  eventColor="white"
                  eventTextColor="black"
                  // viewTitle={handleViewTitle}
                  // validRange={{ start: actualDueDate ? new Date(2000, 1, 1) : new Date(), end: '9999-01-01' }}
                  eventContent={(
                    eventInfo // custom render function
                  ) =>
                    eventInfo.event._def.extendedProps.portfolioTickets ? (
                      <PortfolioTickets
                        onPortfolioExpand={handlePortfolioExpand}
                        {...eventInfo}
                      />
                    ) : (
                      <EventContent
                        onPortfolioCollapse={handlePortfolioCollapse}
                        onMouseEnter={(event) => {
                          if (!eventInfo.event.extendedProps.itemId) {
                            return;
                          }
                          const rect =
                            event.currentTarget.getBoundingClientRect();
                          const { itemId, ticketGroupId, note } =
                            eventInfo.event.extendedProps;
                          setTimeout(() => {
                            if (!event.target.matches(":hover")) {
                              return;
                            }
                            setTooltipTicket({
                              id: itemId,
                              ticketGroupId,
                              note,
                              top: rect.y,
                              left: rect.x,
                              width: rect.width,
                            });
                          }, 2000);
                        }}
                        onMouseLeave={onMouseLeaveEvent}
                        {...eventInfo}
                      />
                    )
                  }
                  eventDrop={handleEventDrop}
                  eventClick={handleEventClick}
                  // viewDidMount={setupDragula}
                  // viewDidMount={(el) => {
                  //   // let _month = el.view.currentStart.getMonth() + 1
                  //   // let _year = el.view.currentStart.getFullYear()
                  //   // loadCurrentMonthData(_month, _year)
                  //   // isDataLoaded.current = true
                  //   const elems = document.getElementsByClassName(
                  //     "fc-daygrid-day-events"
                  //   );
                  //   console.log(elems);
                  //   const drake = dragula([...elems]);

                  //   const getDate = (el) =>
                  //     el.parentElement.parentElement.getAttribute("data-date");

                  //   const getEventAttr = (
                  //     el,
                  //     key = "data-id",
                  //     className = "event"
                  //   ) =>
                  //     el
                  //       .getElementsByClassName(className)
                  //       .item(0)
                  //       ?.getAttribute(key);

                  //   drake.on("drop", (el, target, source) => {
                  //     const targetDay = getDate(target);
                  //     const sourceDay = getDate(source);
                  //     const ticketGroupId = getEventAttr(el);
                  //     const ticketId = getEventAttr(el, "data-ticket-id");
                  //     const portfolioId = getEventAttr(
                  //       el,
                  //       "data-portfolio-id",
                  //       "portfolio-item-wrapper"
                  //     );
                  //     const revert = () => drake.cancel(true);
                  //     if (targetDay !== sourceDay) {
                  //       if (ticketId === null) {
                  //         revert();
                  //         return;
                  //       }

                  //       //handleEventDayChange(ticketGroupId, targetDay, revert);
                  //       // NOTE: uncomment line below and delete line above for "DD calendar new feature"
                  //       handleEventDayChange(
                  //         ticketId || portfolioId,
                  //         targetDay,
                  //         revert
                  //       );
                  //     } else {
                  //       if (!ticketId && !portfolioId) {
                  //         revert();
                  //         return;
                  //       }
                  //       const start = getEventAttr(el, "data-start");
                  //       const portfolioStart = getEventAttr(
                  //         el,
                  //         "data-start",
                  //         "portfolio-item-wrapper"
                  //       );
                  //       let newPriority;
                  //       for (let i = 0; i < target.children.length; i++) {
                  //         const child = target.children.item(i);
                  //         if (getEventAttr(child) === ticketGroupId) {
                  //           newPriority = i + 1;
                  //         }
                  //       }
                  //       if (!newPriority) {
                  //         newPriority = target.children.length;
                  //       }
                  //       handleEventOrderChange(
                  //         ticketId || portfolioId,
                  //         newPriority,
                  //         revert,
                  //         moment(start || portfolioStart).format("YYYY-MM-DD")
                  //       );
                  //     }
                  //   });
                  // }}
                  bootstrapFontAwesome={true}
                />
              )}
            </LoadingWrapper>
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default Calendar;
