import React from "react";

// All pages that rely on 3rd party components (other than Bootstrap) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import AdminPanelLayout from "./layouts/AdminPanel";
import DocLayout from "./layouts/Doc";

// Guards
import AuthGuard from "./components/guards/AuthGuard";
import AdminGuard from "./components/guards/AdminGuard";

// Dashboards
import Default from "./pages/dashboards/Default";
import Analytics from "./pages/dashboards/Analytics";
import SaaS from "./pages/dashboards/SaaS";
import Social from "./pages/dashboards/Social";
import Crypto from "./pages/dashboards/Crypto";
import Customers from "./pages/dashboards/Customers";
import JobDetailsWrapper from "./pages/jobDetails/JobDetailsWrapper";
import Comps from "./pages/dashboards/Comps";
import Filters from "./pages/dashboards/Filters";

// Pages
import Profile from "./pages/pages/Profile";
import Settings from "./pages/pages/Settings";
import Clients from "./pages/pages/Clients";
import Projects from "./pages/pages/Projects";
import Invoice from "./pages/pages/Invoice";
import Pricing from "./pages/pages/Pricing";
import Tasks from "./pages/pages/Tasks";
import Chat from "./pages/pages/Chat";
import Blank from "./pages/pages/Blank";
import JobsFrontDesk from "./pages/frontDesk/frontDesk";
import Users from "./pages/users";

// Auth
import Page500 from "./pages/auth/Page500";
import Page404 from "./pages/auth/Page404";
import ComingSoon from "./pages/auth/ComingSoon";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";
import ChangePassword from "./pages/auth/ChangePassword";

// UI components
import Alerts from "./pages/ui/Alerts";
import Buttons from "./pages/ui/Buttons";
import Cards from "./pages/ui/Cards";
import Carousel from "./pages/ui/Carousel";
import EmbedVideo from "./pages/ui/EmbedVideo";
import General from "./pages/ui/General";
import Grid from "./pages/ui/Grid";
import Modals from "./pages/ui/Modals";
import Offcanvas from "./pages/ui/Offcanvas";
import Tabs from "./pages/ui/Tabs";
import Typography from "./pages/ui/Typography";

// Icons
import Feather from "./pages/icons/Feather";
import FontAwesome from "./pages/icons/FontAwesome";

// Forms
import Layouts from "./pages/forms/Layouts";
import BasicInputs from "./pages/forms/BasicInputs";
import InputGroups from "./pages/forms/InputGroups";
import FloatingLabels from "./pages/forms/FloatingLabels";
import AdvancedInputs from "./pages/forms/AdvancedInputs";
import Formik from "./pages/forms/Formik";
import Editors from "./pages/forms/Editors";

// Tables
import Tables from "./pages/tables/Tables";
import Pagination from "./pages/tables/Pagination";
import ColumnSorting from "./pages/tables/ColumnSorting";
import ColumnFiltering from "./pages/tables/ColumnFiltering";
import RowExpanding from "./pages/tables/RowExpanding";
import RowSelection from "./pages/tables/RowSelection";

// Charts
import Chartjs from "./pages/charts/Chartjs";
import ApexCharts from "./pages/charts/ApexCharts";

// Notifications
import Notifications from "./pages/notifications/Notifications";

// Maps
import GoogleMaps from "./pages/maps/GoogleMaps";
import VectorMaps from "./pages/maps/VectorMaps";

// Calendars
import InspectionCalendar from "./pages/calendar/Inspection";
import DueDateCalendar from "./pages/calendar/DueDate";

// Documentation
import Introduction from "./pages/docs/Introduction";
import GettingStarted from "./pages/docs/GettingStarted";
import Routing from "./pages/docs/Routing";
import Auth0 from "./pages/docs/auth/Auth0";
import Cognito from "./pages/docs/auth/Cognito";
import Firebase from "./pages/docs/auth/Firebase";
import JWT from "./pages/docs/auth/JWT";
import Guards from "./pages/docs/Guards";
import APICalls from "./pages/docs/APICalls";
import Redux from "./pages/docs/Redux";
import Internationalization from "./pages/docs/Internationalization";
import EnvironmentVariables from "./pages/docs/EnvironmentVariables";
import ESLintAndPrettier from "./pages/docs/ESLintAndPrettier";
import Deployment from "./pages/docs/Deployment";
import MigratingToNextJS from "./pages/docs/MigratingToNextJS";
import Support from "./pages/docs/Support";
import Changelog from "./pages/docs/Changelog";
import AdminPage from "./pages/admin";
import CompTypeColumnsPage from "./pages/admin/customPages/CompTypeColumnsPage";
import CompTypeExportColumnsPage from "./pages/admin/customPages/CompTypeExportColumnsPage";

// Protected routes
import ProtectedPage from "./pages/protected/ProtectedPage";
import Tickets from "./pages/dashboards/Tickets";
// import Tickets2 from "./pages/CreateJob/createJob";

import CustomerContacts from "./pages/dashboards/CustomerContacts";
import EditContacts from "./pages/dashboards/CustomerContacts/edit";
import Dashboard from "./layouts/Dashboard";
import { Link, Navigate } from "react-router-dom";

import EventView from "./pages/EventView";
import InspectionFormQuestions from "./pages/admin/customPages/InspectionFormQuestions";
import DueDateWrapper from "./pages/calendar/DueDateWrapper";
import StepsPage from "./pages/admin/customPages/StepsPage";
import TasksBoardPage from "./pages/admin/customPages/TasksBoardPage";
import ChangeHistoryPage from "./pages/admin/customPages/ChangeHistoryPage";
import SnapshotPage from "./pages/admin/customPages/SnapshotPage";

const routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <Dashboard />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <JobsFrontDesk />,
      },
      {
        path: "change-password",
        element: <ChangePassword />,
      },
    ],
  },
  {
    path: "/admin",
    element: (
      <AdminGuard>
        <AdminPanelLayout />
      </AdminGuard>
    ),
    children: [
      {
        path: "tasksboard",
        element: <TasksBoardPage />,
      },
      {
        path: "changehistory",
        element: <ChangeHistoryPage />,
      },
      {
        path: "snapshot",
        element: <SnapshotPage />,
      },
      {
        path: "steps",
        element: <StepsPage />,
      },
      {
        path: "valueType",
        element: <AdminPage path="ValueType" />,
      },
      {
        path: "quoteTimeslots",
        element: <AdminPage path="QuoteTimeSlot" hasDays hasUpdate hasDelete idKey="id" />,
      },
      {
        path: "propertyType",
        element: <AdminPage path="PropertyType" hasType />,
      },
      {
        path: "jobPurpose",
        element: (
          <AdminPage
            path="JobPurpose"
            hasUpdate
            hasDelete
            idKey="jobPurposeId"
          />
        ),
      },
      {
        path: "jobAssignmentType",
        element: <AdminPage path="JobAssignmentType" hasType />,
      },
      {
        path: "portfolio",
        element: (
          <AdminPage path="Portfolio" hasCreated hasDisplayOrder={false} />
        ),
      },
      {
        path: "unitType",
        element: (
          <AdminPage path="UnitType" hasLabel hasUpdate hasDelete idKey="id" />
        ),
      },
      {
        path: "leaseType",
        element: (
          <AdminPage path="LeaseType" hasLabel hasUpdate hasDelete idKey="id" />
        ),
      },
        {
            path: "basementType",
            element: (
                <AdminPage path="BasementType" hasLabel hasUpdate hasDelete idKey="id" />
            ),
        },
        {
            path: "bedroomsClassification",
            element: (
                <AdminPage path="BedroomsClassification" hasLabel hasUpdate hasDelete idKey="id" />
            ),
        },
        {
            path: "lotShape",
            element: (
                <AdminPage path="LotShape" hasLabel hasUpdate hasDelete idKey="id" />
            ),
        },
        {
            path: "lotType",
            element: (
                <AdminPage path="LotType" hasLabel hasUpdate hasDelete idKey="id" />
            ),
        },
        {
            path: "rentRegulationStatus",
            element: (
                <AdminPage path="RentRegulationStatus" hasLabel hasUpdate hasDelete idKey="id" />
            ),
        },
        {
            path: "buildingClass",
            element: (
                <AdminPage path="BuildingClass" hasLabel hasUpdate hasDelete idKey="id" />
            ),
        },
      {
        path: "outdoorSpaceType",
        element: (
          <AdminPage
            path="OutdoorSpaceType"
            hasLabel
            hasUpdate
            hasDelete
            idKey="id"
          />
        ),
      },
      {
        path: "floorLocation",
        element: (
          <AdminPage
            path="FloorLocation"
            hasLabel
            hasUpdate
            hasDelete
            idKey="id"
          />
        )
      },
      {
        path: "zone",
        element: (
          <AdminPage path="Zone" hasLabel hasUpdate hasDelete idKey="id" />
        ),
      },
      {
        path: "zoneOverlay",
        element: (
          <AdminPage
            path="ZoneOverlay"
            hasLabel
            hasUpdate
            hasDelete
            idKey="id"
          />
        ),
      },
      {
        path: "compCondition",
        element: (
          <AdminPage
            path="CompCondition"
            hasLabel
            hasUpdate
            hasDelete
            idKey="id"
          />
        ),
      },
      {
        path: "compType",
        element: (
          <AdminPage
            path="CompType"
            hasDisplayOrder={false}
            hasUpdate
            hasDelete
            CustomAction={({ item }) => (
              <>
                <Link
                  to={`/admin/compColumns/${item.id}`}
                  className="me-2 btn btn-sm btn-light"
                >
                  Columns
                </Link>
                <Link
                  to={`/admin/compExportColumns/${item.id}`}
                  className="btn btn-sm btn-light"
                >
                  Export Columns
                </Link>
              </>
            )}
          />
        ),
      },
      {
        path: "compColumns/:id",
        element: <CompTypeColumnsPage />,
      },
      {
        path: "compExportColumns/:id",
        element: <CompTypeExportColumnsPage />,
      },
      {
        path: "compKey",
        element: (
          <AdminPage path="CompKey" hasLabel hasUpdate hasDelete idKey="id" />
        ),
      },
      {
        path: "users",
        element: <Users />,
      },
      {
        path: "inspectors",
        element: <Users userType="Inspector" />,
      },
      {
        path: "inspectionQuestionForms",
        element: (
          <AdminPage
            path="InspectionQuestionForm"
            idKey="id"
            CustomAction={({ item }) => (
              <Link
                to={`/admin/inspectionFormQuestions/${item.id}`}
                className="me-2 btn btn-sm btn-light"
              >
                Questions
              </Link>
            )}
            hasDelete
            hasUpdate
          />
        ),
      },
      {
        path: "inspectionFormQuestions/:id",
        element: <InspectionFormQuestions />,
      },
      {
        path: "",
        element: <Navigate to="valueType" />,
      },
      {
        path: "*",
        element: <Navigate to="valueType" />,
      },
    ],
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "default",
        element: (
          <AuthGuard>
            <Default />
          </AuthGuard>
        ),
      },
      {
        path: "tickets",
        element: (
          <AuthGuard>
            <Tickets />
          </AuthGuard>
        ),
      },
      {
        path: "event-view",
        element: <EventView />,
      },
      // {
      //   path: "tickets2",
      //   element:
      //     <AuthGuard>
      //       <Tickets2 />
      //     </AuthGuard>,
      // },
      {
        //Ticket ID/ Ticket Group ID
        path: "tickets/:ticketGroupId/:ticketId",
        element: (
          <AuthGuard>
            <Tickets />
          </AuthGuard>
        ),
      },
      {
        path: "job/:ticketGroupId/:ticketId",
        element: (
          <AuthGuard>
            <JobDetailsWrapper />
          </AuthGuard>
        ),
      },
      {
        path: "contacts/:id",
        element: (
          <AuthGuard>
            <CustomerContacts />
          </AuthGuard>
        ),
      },
      {
        path: "editContacts/:id",
        element: (
          <AuthGuard>
            <EditContacts />
          </AuthGuard>
        ),
      },
      {
        path: "customers",
        element: (
          <AuthGuard>
            <Customers />
          </AuthGuard>
        ),
      },
      {
        path: "create-comp",
        element: (
          <AuthGuard>
            <Comps />
          </AuthGuard>
        ),
      },
      {
        path: "update-comp",
        element: (
          <AuthGuard>
            <Comps />
          </AuthGuard>
        )
      },
      {
        path: "comps",
        element: (
          <AuthGuard>
            <Filters />
          </AuthGuard>
        ),
      },
      {
        path: "analytics",
        element: <Analytics />,
      },
      {
        path: "saas",
        element: <SaaS />,
      },
      {
        path: "social",
        element: <Social />,
      },
      {
        path: "crypto",
        element: <Crypto />,
      },
    ],
  },
  {
    path: "pages",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "clients",
        element: <Clients />,
      },
      {
        path: "projects",
        element: <Projects />,
      },
      {
        path: "invoice",
        element: <Invoice />,
      },
      {
        path: "pricing",
        element: <Pricing />,
      },
      {
        path: "tasks",
        element: <Tasks />,
      },
      {
        path: "chat",
        element: <Chat />,
      },
      {
        path: "blank",
        element: <Blank />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "coming-soon",
        element: <ComingSoon />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "ui",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "alerts",
        element: <Alerts />,
      },
      {
        path: "buttons",
        element: <Buttons />,
      },
      {
        path: "cards",
        element: <Cards />,
      },
      {
        path: "carousel",
        element: <Carousel />,
      },
      {
        path: "embed-video",
        element: <EmbedVideo />,
      },
      {
        path: "general",
        element: <General />,
      },
      {
        path: "grid",
        element: <Grid />,
      },
      {
        path: "modals",
        element: <Modals />,
      },
      {
        path: "tabs",
        element: <Tabs />,
      },
      {
        path: "offcanvas",
        element: <Offcanvas />,
      },
      {
        path: "typography",
        element: <Typography />,
      },
    ],
  },
  {
    path: "icons",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "feather",
        element: <Feather />,
      },
      {
        path: "font-awesome",
        element: <FontAwesome />,
      },
    ],
  },
  {
    path: "forms",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "layouts",
        element: <Layouts />,
      },
      {
        path: "basic-inputs",
        element: <BasicInputs />,
      },
      {
        path: "input-groups",
        element: <InputGroups />,
      },
      {
        path: "floating-labels",
        element: <FloatingLabels />,
      },
    ],
  },
  {
    path: "tables",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Tables />,
      },
    ],
  },
  {
    path: "form-plugins",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "advanced-inputs",
        element: <AdvancedInputs />,
      },
      {
        path: "formik",
        element: <Formik />,
      },
      {
        path: "editors",
        element: <Editors />,
      },
    ],
  },
  {
    path: "advanced-tables",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "pagination",
        element: <Pagination />,
      },
      {
        path: "column-sorting",
        element: <ColumnSorting />,
      },
      {
        path: "column-filtering",
        element: <ColumnFiltering />,
      },
      {
        path: "row-expanding",
        element: <RowExpanding />,
      },
      {
        path: "row-selection",
        element: <RowSelection />,
      },
    ],
  },
  {
    path: "charts",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "chartjs",
        element: <Chartjs />,
      },
      {
        path: "apexcharts",
        element: <ApexCharts />,
      },
    ],
  },
  {
    path: "notifications",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Notifications />,
      },
    ],
  },
  {
    path: "maps",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "google-maps",
        element: <GoogleMaps />,
      },
      {
        path: "vector-maps",
        element: <VectorMaps />,
      },
    ],
  },
  {
    path: "calendar",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "inspection",
        element: <InspectionCalendar />,
      },
      {
        path: "dueDate-commercial",
        element: <DueDateCalendar /> //localStorage.getItem('DD_type') == 'internal' ? (<DueDateCalendar internal />) : (),
      },
      {
        path: "duedate",
        element: <DueDateWrapper />
      },
      {
        path: "dueDate-residential",
        element: <DueDateCalendar residential />,
      },
      {
        path: 'internal-dueDate-commercial',
        element: <DueDateCalendar internal />,
      },
      {
        path: "internal-dueDate-residential",
        element: <DueDateCalendar residential internal />,
      },
    ],
  },
  {
    path: "docs",
    element: <DocLayout />,
    children: [
      {
        path: "introduction",
        element: <Introduction />,
      },
      {
        path: "getting-started",
        element: <GettingStarted />,
      },
      {
        path: "routing",
        element: <Routing />,
      },
      {
        path: "auth/auth0",
        element: <Auth0 />,
      },
      {
        path: "auth/cognito",
        element: <Cognito />,
      },
      {
        path: "auth/firebase",
        element: <Firebase />,
      },
      {
        path: "auth/jwt",
        element: <JWT />,
      },
      {
        path: "guards",
        element: <Guards />,
      },
      {
        path: "api-calls",
        element: <APICalls />,
      },
      {
        path: "redux",
        element: <Redux />,
      },
      {
        path: "internationalization",
        element: <Internationalization />,
      },
      {
        path: "environment-variables",
        element: <EnvironmentVariables />,
      },
      {
        path: "eslint-and-prettier",
        element: <ESLintAndPrettier />,
      },
      {
        path: "deployment",
        element: <Deployment />,
      },
      {
        path: "migrating-to-next-js",
        element: <MigratingToNextJS />,
      },
      {
        path: "support",
        element: <Support />,
      },
      {
        path: "changelog",
        element: <Changelog />,
      },
    ],
  },
  {
    path: "private",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ProtectedPage />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "affiliates",
        element: <ComingSoon />,
      },
      {
        path: "partners",
        element: <ComingSoon />,
      },
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
