import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form, Modal, Spinner } from "react-bootstrap";
import Select from "react-select";
import { USER_ROLES } from "../../constants";
import upperFirst from "lodash/upperFirst";
import { SketchPicker, CompactPicker, ChromePicker } from 'react-color';

const UserModal = forwardRef(
  (
    {
      initialValues = {
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        active: true,
        roles: [],
      },
      onSubmit,
      onClose,
      userType,
    },
    ref
  ) => {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const [showColorPicker, setShowColorPicker] = useState(false);

    useImperativeHandle(
      ref,
      () => ({
        open() {
          setShow(true);
        },
      }),
      []
    );

    const handleClose = useCallback(() => {
      onClose();
      setShow(false);
      setShowColorPicker(false);
    }, [onClose]);

    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          {!initialValues.id ? `Register ${userType}` : `Update ${userType}`}
        </Modal.Header>
        <Modal.Body className="m-3">
          <Formik
            initialValues={{
              role: initialValues.roles.map((role) => ({
                value: role,
                label: upperFirst(role),
              })),
              ...initialValues,
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
              firstName: Yup.string().required("First name is required"),
              lastName: Yup.string().required("Last name is required"),
              password: initialValues.id
                ? undefined
                : Yup.string().required("Last name is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                setLoading(true);
                values.role = values.role.map((item) => item.value);
                await onSubmit(values);
                handleClose();
              } catch (error) {
                const message =
                  error.message ||
                  (typeof error === "string" && error) ||
                  "Something went wrong";
                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
              } finally {
                setLoading(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              touched,
              values,
            }) => (
              <Form id={`${userType}-form`} onSubmit={handleSubmit}>
                {errors.submit && (
                  <Alert className="my-3" variant="danger">
                    <div className="alert-message">{errors.submit}</div>
                  </Alert>
                )}
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    label="Email Address"
                    value={values.email}
                    isInvalid={Boolean(touched.email && errors.email)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.email && (
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>First name</Form.Label>
                  <Form.Control
                    name="firstName"
                    label="First name"
                    value={values.firstName}
                    isInvalid={Boolean(touched.firstName && errors.firstName)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.firstName && (
                    <Form.Control.Feedback type="invalid">
                      {errors.firstName}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Last name</Form.Label>
                  <Form.Control
                    name="lastName"
                    label="Last name"
                    value={values.lastName}
                    isInvalid={Boolean(touched.lastName && errors.lastName)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.lastName && (
                    <Form.Control.Feedback type="invalid">
                      {errors.lastName}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Check
                    id="active-checkbox"
                    type="checkbox"
                    name="active"
                    label="Active"
                    checked={values.active}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Role</Form.Label>
                  {show && (
                    <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      value={values.role}
                      placeholder="Role"
                      options={[
                        {
                          value: USER_ROLES.INSPECTOR,
                          label: upperFirst(USER_ROLES.INSPECTOR),
                        },
                        {
                          value: USER_ROLES.PRIORITIZER,
                          label: upperFirst(USER_ROLES.PRIORITIZER),
                        },
                        {
                          value: USER_ROLES.SIGNINGAPPRAISER,
                          label: "Signing Appraiser"
                        },
                        {
                          value: USER_ROLES.AFFILIATESADMIN,
                          label: "Affiliates Admin"
                        },
                        {
                          value: USER_ROLES.AFFILIATE,
                          label: "Affiliate"
                        },
                        // {
                        //   value: 'admin',
                        //   label: 'Admin'
                        // }
                      ]}
                      isMulti
                      onBlur={handleBlur}
                      onChange={(value) =>
                        handleChange({ target: { value, name: "role" } })
                      }
                    />
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Color</Form.Label>
                  {values.color ? (<div style={{ borderRadius: '5px', backgroundColor: values.color, height: '20px', width: '20px' }}></div>) : <p>No color assigned</p>}
                  {showColorPicker ? <SketchPicker
                    className="mt-2"
                    name="color"
                    disableAlpha={true}
                    presetColors={[]}
                    color={values.color ?? '#FFFFFF'}
                    onChangeComplete={e => setFieldValue('color', e.hex, false)}
                  /> : (<Button className="mt-2" onClick={e => setShowColorPicker(true)}>{initialValues.id ? 'Change color' : 'Set Color'}</Button>)}
                </Form.Group>

                {!initialValues.id && (
                  <Form.Group className="mb-3">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      autoComplete="new-password"
                      name="password"
                      label="Password"
                      value={values.password}
                      isInvalid={Boolean(touched.password && errors.password)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {!!touched.password && (
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                )}
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={loading}>
            Close
          </Button>
          <Button
            form={`${userType}-form`}
            type="submit"
            variant="primary"
            disabled={loading}
          >
            {loading && (
              <Spinner animation="border" size="sm" className="me-2" />
            )}
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
);

export default UserModal;
